import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext } from '@hello-pangea/dnd'
import { isEmpty } from 'lodash'
import useRankOneOnOneMeeting from 'src/queries/virtualPortal/useRankOneOnOneMeeting'
import useFetchOneOnOneMeetingWishlist from 'src/queries/virtualPortal/useFetchOneOnOneMeetingWishlist'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import DraggableList from './DraggableList'
import WishlistTableHeader from './WishlistTableHeader'
import FooterButtons from '../components/FooterButtons'
import UserDetailsModal from '../components/UserDetailsModal'

const WishlistRank = ({ createMeeting, deleteMeeting }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { wishlistContacts, potentialWishlistContacts } = useFetchOneOnOneMeetingWishlist()
  const { mutate } = useRankOneOnOneMeeting()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [dragResult, setDragResult] = useState({ destination: '' })

  const state = useMemo(() => {
    if (!dragResult.destination) {
      return {
        wishlistContacts,
        potentialWishlistContacts
      }
    }
    if (dragResult.destination.droppableId === 'wishlistContacts' && dragResult.source.droppableId === 'potentialWishlistContacts') {
      const [reorderedItem] = potentialWishlistContacts.splice(dragResult.source.index, 1)
      reorderedItem.added = true
      reorderedItem.on_other_wishlist = true
      wishlistContacts.splice(dragResult.destination.index, 0, reorderedItem)
      return {
        wishlistContacts,
        potentialWishlistContacts
      }
    } else {
      const [reorderedItem] = wishlistContacts.splice(dragResult.source.index, 1)
      wishlistContacts.splice(dragResult.destination.index, 0, reorderedItem)
      return {
        wishlistContacts,
        potentialWishlistContacts
      }
    }
  }, [wishlistContacts, potentialWishlistContacts, dragResult])

  const openModal = useCallback((user) => {
    setSelectedUser(user)
    setIsModalOpen(true)
  }, [])

  const handleOnDragEnd = useCallback((result) => {
    setDragResult(result)
  }, [])

  const onClick = useCallback((wishlistId, type) => {
    if (type === 'delete') {
      deleteMeeting(wishlistId)
    } else if (type === 'create') {
      createMeeting(wishlistId, 'true')
    }
  }, [deleteMeeting, createMeeting])

  const rank = useCallback(async (url) => {
    await mutate(state.wishlistContacts)
    setIsSaving(false)
    navigate(url)
  }, [state])

  const onSubmit = useCallback(() => {
    setIsSaving(true)
    const url = `${location.pathname.replace('wishlist_rank', 'availability')}${location.search}`
    rank(url)
  }, [location, rank])

  const onBack = useCallback(() => {
    const url = `${location.pathname.replace('wishlist_rank', 'wishlist')}${location.search}`
    rank(url)
  }, [location, rank])

  if (isEmpty(state.wishlistContacts)) {
    const { token } = useParams()
    return (
      <>
        <WishlistTableHeader />
        <p>You have no selections to rank. Please go to the
          <a href={`/${token}/wishlist/wizard?wizard_from=web`}>&quot;My Preferences&quot;</a> page to update your meeting preferences.
        </p>
      </>
    )
  }

  return (
    <div>
      <div className={generalStyles.mt30}>
        <h3 style={{ fontWeight: 'normal' }} className={fontStyles.h2alternate2bolddark}>Rank your preferences for peer-to-peer meetings</h3>
        <p>Please press and hold the arrows to move and reorder your ranking preferences.</p>
        <p>If you select &apos;Remove,&apos; the person is removed from your list (They will not be able to see that you blocked them).
          They can be added back in by returning to the &apos;My Preferences&apos; page.</p>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <DraggableList
          contacts={state.wishlistContacts}
          id='wishlistContacts'
          openModal={openModal}
          onClick={onClick}
        />
        {state.potentialWishlistContacts.length > 0 &&
        <>
          <div className={generalStyles.mt30}>
            <h3 style={{ fontWeight: 'normal' }} className={fontStyles.h2alternate2bolddark}>Top Listing</h3>
            <p>This list shows executives who have ranked you among their top peers to meet with. If you would like to meet with them, you can use the arrows to drag the lines up to your ranked list above.</p>
            <p>If you select &quot;Block,&quot; that person will not be able to schedule a meeting with you.</p>
          </div>
          <DraggableList
            contacts={state.potentialWishlistContacts}
            id='potentialWishlistContacts'
            openModal={openModal}
            onClick={onClick}
          />
        </>
        }
      </DragDropContext>
      <UserDetailsModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} user={selectedUser} />
      <FooterButtons isLoading={isSaving} onBack={onBack} onClick={onSubmit} />
    </div>
  )
}

export default WishlistRank
