import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const deleteOneOnOneMeeting = async (params, config) => {
  const response = await deleteData(`/virtual_portal/delete_event_one_on_one_meeting_wishlists/${params.wishlistId}`, { ...config })
  return response
}

const useDeleteOneOnOneMeetingWishlist = () => {
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => deleteOneOnOneMeeting(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist_users'] })
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_meeting_wishlist'] })
    }
  })

  return { mutateAsync, mutate }
}

export default useDeleteOneOnOneMeetingWishlist
