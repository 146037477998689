import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import AvatarEditor from 'react-avatar-editor'
import IcomoonReact from 'icomoon-react'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import useUploadPhoto from 'src/queries/currentUser/useUploadPhoto'
import Button from 'src/components/Elements/Button'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import settingStyles from '../settingStyles.module.scss'

const ImageEditor = ({ selectedImage }) => {
  const { currentUser } = useGlobalContext()
  const { mutate } = useUploadPhoto()
  const editorRef = useRef(null)
  const isMobileSize = useIsMobileQuery()
  const imageToEdit = selectedImage || currentUser.photo
  const width = isMobileSize ? 200 : 500
  const height = isMobileSize ? 200 : 500
  const [scale, setScale] = useState(1)
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [rotate, setRotation] = useState(0)
  const [image, setImage] = useState(imageToEdit)

  const handleCrop = async () => {
    const img = editorRef.current.getImageScaledToCanvas().toDataURL()
    setImage(img)
  }

  const saveBlob = async () => {
    const canvas = editorRef.current.getImageScaledToCanvas()
    return new Promise((resolve) => {
      return canvas.toBlob((file) => {
        file.name = `${currentUser.id}_profile_image.jpeg`
        file.lastModifiedDate = new Date()
        resolve(file)
      }, 'image/jpeg')
    })
  }

  const handleSaveImage = async () => {
    await handleCrop()
    const blob = await saveBlob()
    const formData = new FormData()
    formData.append('file', blob)
    mutate(formData)
  }

  return (
    <div className={settingStyles.imageEditor}>
      <div className={generalStyles.pb50}>
        <AvatarEditor
          ref={editorRef}
          image={image}
          scale={parseFloat(scale)}
          width={width}
          height={height}
          position={position}
          onPositionChange={(pos) => setPosition(pos)}
          rotate={parseFloat(rotate)}
          borderRadius={300}
        />
      </div>
      <div
        className={classNames(
          generalStyles.flexColumn,
          generalStyles.centeredText
        )}
      >
        <div className={settingStyles.rangeContainer}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon={'Control-SubtractIcon-XL-WhiteOutline'}
            color={variables.darkGrey}
            className={settingStyles.minusIcon}
          />
          <input
            id="scale"
            name="scale"
            type="range"
            onChange={(e) => setScale(e.target.value)}
            min="0.1"
            max="2"
            step="0.01"
            defaultValue="1"
          />
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon={'Control-AddIcon-XL-WhiteOutline'}
            color={variables.darkGrey}
            className={settingStyles.addIcon}
          />
        </div>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <span className={fontStyles.bodyregulargrey}>ZOOM</span>
        </div>
      </div>
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.gap20,
          generalStyles.flexCentered
        )}
      >
        <Button
          size={'large'}
          label={'Rotate'}
          color={'evantaBlueOutline'}
          onClick={() => setRotation(rotate - 90)}
        />
        <Button
          size={'large'}
          label={'Save'}
          color={'evantaBlue'}
          onClick={handleSaveImage}
        />
      </div>
    </div>
  )
}

export default ImageEditor
