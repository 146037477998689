import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const updateOneOnOneMeeting = async (params, config) => {
  const response = await putData(`/virtual_portal/update_event_one_on_one_meeting_wishlists/${params.wishlistId}`, {
    event_one_on_one_meeting_wishlist: {
      ...params.data
    }
  },
  { ...config }
  )
  return response
}

const useUpdateOneOnOneMeetingWishlist = () => {
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => updateOneOnOneMeeting(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist_users'] })
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_meeting_wishlist'] })
    }
  })

  return { mutateAsync, mutate }
}

export default useUpdateOneOnOneMeetingWishlist
