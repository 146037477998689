import defaultAxios from 'axios'
import Cookies from 'js-cookie'

const baseUrl = import.meta.env.VITE_API_BASE_URL

const baseHeaders = {
  X_API_Tenant: 'evantaconnect',
  'Content-Type': 'application/json'
}

// for login
// const unauthApi = defaultAxios.create({
//   baseURL: baseUrl,
//   withCredentials: false,
//   headers: baseHeaders
// })

const axios = defaultAxios.create({
  baseURL: baseUrl,
  headers: {
    ...baseHeaders,
    X_API_EMAIL: Cookies.get('email')
  },
  validateStatus: () => true
})

axios.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token')
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const fetchData = (path, params) => {
  const id = params?.eventId || ''
  const timestamp = params?.timestamp ? `/${params.timestamp}` : ''

  return axios.get(`${path}${id}${timestamp}`, { ...params })
    .then(response => response)
    .catch(error => error)
}

const postData = (path, params, config) => {
  return axios.post(`${path}`, JSON.stringify(params), { ...config })
    .then(response => response)
    .catch(error => error)
}

const putData = (path, params, config) => {
  return axios.put(`${path}`, JSON.stringify(params), { ...config })
    .then(response => response)
    .catch(error => error)
}

const deleteData = (path, config) => {
  return axios.delete(`${path}`, { ...config })
}

const postFile = async (path, params) => {
  const token = Cookies.get('token')
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      X_API_EMAIL: Cookies.get('email'),
      Authorization: token
    }
  }
  return axios.post(`${path}`, params, config)
    .then(response => response)
    .catch(error => error)
}

export { axios, fetchData, postData, putData, postFile, deleteData }
