import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import PastEventCard from 'src/features/programs/home/components/PastEventCard'
import useFetchPastEvents from 'src/queries/events/useFetchPastEvents'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import ViewMore from './ViewMore'

const ProgramsHomePage = () => {
  const [showMore, setShowMore] = useState(false)
  const [displayedData, setDisplayedData] = useState([])
  const [limit, setLimit] = useState(5)

  const { data, eventCount, isLoading } = useFetchPastEvents({ limit, config: { suspense: limit === 5 } })

  const pastEvents = useMemo(() => {
    if (showMore) {
      return data
    } else {
      return data.slice(0, 5)
    }
  }, [data, showMore])

  // This is so when refreshing the data <Suspense /> on the parent component won't trigger
  // to avoid rendering the skeleton after the first render
  useEffect(() => {
    if (!isLoading) {
      setDisplayedData(pastEvents)
    }
  }, [pastEvents, isLoading])

  const onClick = useCallback(() => {
    if (limit === 5) {
      setLimit(0)
    }

    setShowMore(!showMore)
  }, [limit, setLimit, showMore])

  if (isEmpty(data)) return <></>

  return (
    <>
      <div className={generalStyles.mt40} data-testid="pastPrograms">
        <span className={fontStyles.h1regulardark}>Past Programs</span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color='lightGrey' fullWidth />
        </div>
      </div>
      {displayedData.map(event => (
        <div key={event?.id} className={generalStyles.pb20} data-testid={`pastEvent${event?.id}`}>
          <Link to={`/programs/${event?.id}`}>
            <PastEventCard
              event={event}
              eventCardType={event.is_virtual_event ? 'Virtual' : 'In-person'}
            />
          </Link>
        </div>
      ))}
      {eventCount > 5 ? <ViewMore viewMore={showMore} onClick={onClick} /> : null}
    </>
  )
}

export default ProgramsHomePage
