import { useQuery } from '@tanstack/react-query'
import { fetchData } from 'src/api/api-utilities'
import { isEmpty } from 'lodash'

const fetchNextEvent = async (params) => {
  const response = await fetchData('/my_evanta/up_next_event', params)
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchNextEvent = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['up_next_event'],
    queryFn: fetchNextEvent,
    retry: false,
    suspense: true
  })
  const nextEvent = isEmpty(data) ? {} : data.event
  return {
    nextEvent,
    isLoadingNextEvent: isLoading
  }
}

export default useFetchNextEvent
