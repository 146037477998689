import React, { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import useFetchOneOnOneTimeslot from 'src/queries/virtualPortal/useFetchOneOnOneTimeslot'
import useCreateOneOnOneTimeslot from 'src/queries/virtualPortal/useCreateOneOnOneTimeslot'
import useDeleteOneOnOneTimeslot from 'src/queries/virtualPortal/useDeleteOneOnOneTimeslot'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'
import FooterButtons from '../components/FooterButtons'
import usePostCompleteWishlist from 'src/queries/virtualPortal/usePostCompleteWishlist'

const Availability = ({ event }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { p2pDates, timeslots } = useFetchOneOnOneTimeslot()
  const { mutate: postTimeslotMutate } = useCreateOneOnOneTimeslot()
  const { mutate: deleteTimeslotMutate } = useDeleteOneOnOneTimeslot()
  const { mutate: submit } = usePostCompleteWishlist()

  const getDay = useCallback((date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dateObj = new Date(date)
    return days[dateObj.getDay()]
  }, [])

  const toggleDate = useCallback((date) => {
    timeslots[date.event_date].map((time) => {
      if (time.opt_out_id && time.id === date.id) {
        deleteTimeslotMutate({ id: time.opt_out_id })
      } else if (!time.opt_out_id && time.id === date.id) {
        postTimeslotMutate({ id: date.id })
      }
      return ''
    })
  }, [timeslots])

  const onBack = useCallback(() => {
    navigate(`${location.pathname.replace('availability', 'wishlist_rank')}${location.search}`)
  }, [location])

  return (
    <div>
      <h3 style={{ fontWeight: 'normal' }} className={classNames(fontStyles.h2alternate2bolddark, generalStyles.mt30)}>
        Make your meeting selections for this event
      </h3>
      <p>The checked time slots indicate that you are available. Please deselect the time slots for which you are unavailable for peer-to-peer meetings at the {event?.website_display_name}. If your schedule changes, you will be able to update your availability through the portal.</p>
      <div className={virtualPortalStyles.timeslots}>
        {p2pDates.map((date, index) => {
          return (
            <div
              className={virtualPortalStyles.timeslotsWrapper}
              key={index}
            >
              <h3
                style={{ fontWeight: 'normal' }}
                className={classNames(fontStyles.h2alternate2bolddark, generalStyles.mt30)}
              >
                {getDay(date)} - {date}
              </h3>

              {timeslots[date].map((date, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(virtualPortalStyles.timeslotTime, date.opt_out_id !== null && virtualPortalStyles.timeslotTimeSelected)}
                    onClick={() => toggleDate(date)}
                  >
                    <i className={date.opt_out_id !== null ? '' : 'fa fa-check-circle' }></i>
                    {date.time_block_formatted_start_time} - {date.time_block_formatted_end_time}
                  </div>
                )
              })}

            </div>
          )
        })
        }
      </div>
      <FooterButtons onBack={onBack} onClick={() => submit()} />
    </div>
  )
}

export default Availability
