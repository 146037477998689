import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import WishlistBody from './WislistBody'
import FooterButtons from '../components/FooterButtons'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'
import useUpdateOneOnOneMeetingWishlist from 'src/queries/virtualPortal/useUpdateOneOnOneMeetingWishlist'

const Wishlist = ({ surveyUser, searchUsers, listData, createMeeting, deleteMeeting }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { mutate } = useUpdateOneOnOneMeetingWishlist()

  const updateMeeting = useCallback((wishlistId, block, userId) => {
    mutate({
      wishlistId,
      data: {
        is_blocked: block,
        survey_user_id: surveyUser.id,
        impacted_survey_user_id: userId
      }
    })
  }, [surveyUser])

  const setWishlist = useCallback((userId, key) => {
    const isUserBlocked = listData.block.includes(userId)
    const isUserWishlist = listData.include.includes(userId)
    const wishlistId = listData.wishlistUsers.filter(user => user.id === userId)[0].wishlist_id

    if (key === 'include') {
      if (isUserBlocked) {
        updateMeeting(wishlistId, 'false', userId)
      } else if (isUserWishlist) {
        deleteMeeting(wishlistId)
      } else {
        createMeeting(userId, 'false')
      }
    } else {
      if (isUserWishlist) {
        updateMeeting(wishlistId, 'true', userId)
      } else if (isUserBlocked) {
        deleteMeeting(wishlistId)
      } else {
        createMeeting(userId, 'true')
      }
    }
  }, [createMeeting, listData, deleteMeeting, updateMeeting])

  const save = useCallback(() => {
    const url = `${location.pathname.replace('wishlist', 'wishlist_rank')}${location.search}`
    navigate(url)
  }, [navigate, location])

  return (
    <div>
      <h3 style={{ fontWeight: 'normal' }} className={classNames(fontStyles.h2alternate2bolddark, generalStyles.mt30)}>Make your meeting selections for this event</h3>
      <div>
        <p>Please select your top candidates for meetings. The more options you select, the more likely we can match you with a like-minded peer.</p>
        <p>Please note that you are creating a list of preferred meeting partners, but we cannot guarantee that your choices will result in scheduled meetings.</p>
        <div className={virtualPortalStyles.inputGroup}>
          <i className="fa fa-search"></i>
          <input placeholder="Search by Organization or Name" onChange={searchUsers} />
        </div>
        <div className={virtualPortalStyles.wishlistLegend}>
          <span className={virtualPortalStyles.wishlistLegendUp}>
            <i className='fa fa-thumbs-o-up'></i><em> = Add participant to list of potential meetings</em>
          </span>
          <span className={virtualPortalStyles.wishlistLegendDown}>
            <i className='fa fa-thumbs-o-down'></i><em> = Remove participant and be hidden from their list</em>
          </span>
        </div>
      </div>
      <WishlistBody listData={listData} setWishlist={setWishlist}/>
      <FooterButtons onClick={save} />
    </div>
  )
}

export default Wishlist
