import React from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'src/components/Elements/Button'
import virtualPortalStyles from './virtualPortalStyles.module.scss'

const styles = {
  padding: '12px 20px', borderRadius: '5px', fontSize: '16px'
}

const FooterButtons = ({ isLoading, onBack, onClick }) => {
  const location = useLocation()
  const isFirstPage = location.pathname.split('/')[2] === 'wishlist'
  const isLastPage = location.pathname.split('/')[2] === 'availability'

  return (
    <div className={virtualPortalStyles.footerButtons}>
      {!isFirstPage && <Button
        color='solarOutline'
        label='SAVE & PREVIOUS'
        style={{ ...styles, marginRight: '12px' }}
        onClick={onBack}
      />}
      <Button
        color='solar'
        disabled={isLoading}
        label={isLoading ? 'SAVING' : isLastPage ? 'SAVE & COMPLETE' : 'SAVE & CONTINUE'}
        style={{ ...styles, fontSize: '16px' }}
        onClick={onClick}
      />
    </div>
  )
}

export default FooterButtons
