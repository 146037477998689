import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'

const PartnerFollowUpQuestion = ({ radioValue, setRadioValue }) => {
  const radioOptions = [
    { id: 1, value: true, name: 'Yes' },
    { id: 2, value: false, name: 'No' }
  ]
  return (
    <>
      <div className={classNames(generalStyles.mt30, generalStyles.mb20)}>
        <span className={fontStyles.bodybolddark}>
        Would you like to continue the conversation with the speaker(s) from this session?
        </span>
      </div>
      <div
        className={classNames(generalStyles.flexColumn, generalStyles.gap10)}
      >
        {radioOptions?.map((opt) => (
          <label key={opt.id}>
            <input
              type="radio"
              key={opt.id}
              id={opt.id}
              name={opt.name}
              checked={radioValue === opt.value}
              value={opt.value}
              onChange={() => setRadioValue(opt.value)}
              className={generalStyles.mr20}
            />
            {opt.name}
          </label>
        ))}
      </div>
    </>
  )
}

export default PartnerFollowUpQuestion
