import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const fetchSurveyUser = async (config) => {
  const response = await fetchData('/virtual_portal/survey_user', { ...config })
  return response
}

const useFetchSurveyUser = () => {
  const config = getQueryConfig()
  const { data, isLoading } = useQuery({
    queryKey: ['survey_user', config],
    queryFn: () => fetchSurveyUser(config),
    staleTime: Infinity,
    enabled: !!config
  })

  if (data?.response?.status) {
    localStorage.setItem('wizard_error', `${Object.values(data.response.data.errors)[0]}`)
  }

  const surveyUser = isEmpty(data) ? {} : data.data?.survey_user

  return {
    surveyUser,
    isLoading
  }
}

export default useFetchSurveyUser
