import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { postData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const postComplete = async (config) => {
  const response = await postData('/virtual_portal/complete_event_one_on_one_meeting_wishlists',
    null,
    config
  )
  return response
}

const usePostCompleteWishlist = () => {
  const navigate = useNavigate()
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: () => postComplete(config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_timeslot'] })
      navigate(`${location.pathname.replace('availability', 'thankyou')}${location.search}`)
    }
  })

  return { mutateAsync, mutate }
}

export default usePostCompleteWishlist
