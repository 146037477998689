import React, { useEffect, useState } from 'react'
import virtualPortalStyles from './virtualPortalStyles.module.scss'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'

const WizardHeader = ({
  currentUser,
  tab,
  token,
  visitingOneOnOneWizardFrom
}) => {
  const [index, setIndex] = useState([])

  useEffect(() => {
    if (tab === 'wishlist_rank') {
      setIndex([0])
    }
    if (tab === 'availability') {
      setIndex([0, 1])
    }
    if (tab === 'wishlist') {
      setIndex([])
    }
  }, [tab])

  return (
    <>
      {!visitingOneOnOneWizardFrom
        ? (
          ''
        )
        : (
          <div className="welcome">
            <h2 className={virtualPortalStyles.welcomeTitle}>
              Welcome {currentUser?.first_name} {currentUser?.last_name}
            </h2>
            <h5 className={virtualPortalStyles.welcomeSubtitle}>
              {currentUser?.title}, {currentUser?.organization_name}
            </h5>
          </div>
        )}
      <p>
        Thank you for taking part in peer-to-peer meetings. To personalize your
        experience, please complete the following steps.
      </p>
      <div className={virtualPortalStyles.wizardLinks}>
        <div
          className={classNames(
            tab === 'wishlist'
              ? virtualPortalStyles.active
              : virtualPortalStyles.tabs,
            index.includes(0) && virtualPortalStyles.activeTab
          )}
        >
          {/* instead of escaping characters, use SanitizeHtml to prevent XSS */}
          <a href={sanitizeHtml(`/${token}/wishlist/wizard${location.search}`)}>
            MY PREFERENCES
          </a>
        </div>
        <div
          className={classNames(
            tab === 'wishlist_rank'
              ? virtualPortalStyles.active
              : virtualPortalStyles.tabs,
            index.includes(1) && virtualPortalStyles.activeTab
          )}
        >
          <a href={sanitizeHtml(`/${token}/wishlist_rank/wizard${location.search}`)}>
            RANK PREFERENCES
          </a>
        </div>
        <div
          className={
            tab === 'availability'
              ? virtualPortalStyles.active
              : virtualPortalStyles.tabs
          }
        >
          <a href={sanitizeHtml(`/${token}/availability/wizard${location.search}`)}>
            AVAILABILITY
          </a>
        </div>
      </div>
    </>
  )
}

export default WizardHeader
