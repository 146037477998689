import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const fetchOneOnOneMeetingWishlist = async (config) => {
  const response = await fetchData('/virtual_portal/event_one_on_one_meeting_wishlists', { ...config })
  return response.data
}

const useFetchOneOnOneMeetingWishlist = () => {
  const config = getQueryConfig()
  const { data, isLoading } = useQuery({
    queryKey: ['event_one_on_one_meeting_wishlist', config],
    queryFn: () => fetchOneOnOneMeetingWishlist(config),
    staleTime: Infinity,
    enabled: !!config
  })

  const meetingWishlists = isEmpty(data) ? {} : data.event_one_on_one_meeting_wishlists
  let potentialWishlistContacts = meetingWishlists?.event_one_on_one_meetings_wishlisted
  const wishlistSurveyUserIds = meetingWishlists.event_one_on_one_meeting_wishlists?.map((user) => user.impacted_survey_user_id)
  const potentialWishlistSurveyUserIds = potentialWishlistContacts?.map(user => user.survey_user_id).filter(user => !wishlistSurveyUserIds.includes(user.survey_user_id))

  const wishlistContacts = meetingWishlists?.event_one_on_one_meeting_wishlists?.map((user) => {
    return {
      id: user.id,
      photo: user.other_photo,
      full_name: user.other_full_name,
      title: user.other_title,
      organization_name: user.other_organization_name,
      on_other_wishlist: potentialWishlistSurveyUserIds.includes(user.impacted_survey_user_id)
    }
  })

  potentialWishlistContacts = potentialWishlistContacts?.filter((wishlist) => {
    return !wishlistSurveyUserIds.includes(wishlist.survey_user_id)
  })

  return {
    wishlistContacts: wishlistContacts || [],
    potentialWishlistContacts: potentialWishlistContacts || [],
    isLoadingMeetingWishlist: isLoading
  }
}

export default useFetchOneOnOneMeetingWishlist
