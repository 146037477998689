import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { approvedStatuses, eventEnded } from 'src/utils/helpers/EventHelperMethods'
import { notRegisteredForSessions, hasMissingEvals } from 'src/utils/helpers/SessionHelperMethods'
import useFetchFAB from 'src/queries/fab/useFetchFAB'
import FAB from './FAB'
import FabContent from './FabContent'

const fabData = {
  session_registration: {
    type: 'local',
    key: 'session_registration',
    icon: 'Symbol-AgendaIcon-Small-RedOutline',
    text: 'Select your sessions',
    data: 'open_agenda_screen'
  },
  session_evaluation: {
    type: 'local',
    key: 'session_evaluation',
    icon: 'Symbol-AgendaIcon-Small-RedOutline',
    text: 'Complete your session evaluations',
    data: 'open_agenda_screen'
  },
  peer_to_peer: {
    type: 'api',
    key: 'peer_to_peer',
    icon: 'Symbol-DirectoryIcon-GreyOutline',
    text: 'Complete your peer-to-peer selections'
  },
  lps: {
    type: 'api',
    key: 'lps',
    icon: 'Symbol-SurveyIcon-Red',
    text: 'Complete your Leadership Perspective Survey'
  },
  event_evaluation: {
    type: 'api',
    key: 'event_evaluation',
    icon: 'Symbol-AgendaIcon-Small-RedOutline',
    text: 'Complete your event evaluation'
  }
}

const TypeProps = {
  type: PropTypes.oneOf(['content', null])
}

const Fab = ({ type }) => {
  const queryClient = useQueryClient()
  const { fab, isLoadingFAB } = useFetchFAB()
  const { currentEvent, eventId, sessionId, sessions } = useGlobalContext()
  const Component = type ? FabContent : FAB

  const fabItems = useMemo(() => {
    let items = []
    if (!approvedStatuses(currentEvent) || eventEnded(currentEvent)) {
      queryClient.invalidateQueries(`event_fab_${eventId}`)
    }
    if (eventEnded(currentEvent)) {
      items = items.filter(fab => fab.key !== 'session_registration')
    }
    if (notRegisteredForSessions(sessions) && !eventEnded(currentEvent) && currentEvent.event_agenda_status_key === 'event_agenda_status_full_agenda') {
      items = [{ ...items, ...fabData.session_registration }]
    }
    if (hasMissingEvals(sessions)) {
      items = [{ ...items, ...fabData.session_evaluation }]
    }
    return items
  }, [eventId, sessionId, currentEvent, sessions])

  const filteredFabs = useMemo(() => {
    const join = [...fab, ...fabItems]
    return join.map(item => {
      if (fabData[item.key]) {
        return { ...item, ...fabData[item.key] }
      } else {
        return ''
      }
    })
  }, [fabItems, fab])

  if (isEmpty(filteredFabs) || isLoadingFAB) return <></>

  return <Component eventId={eventId} fab={filteredFabs} />
}

Fab.propTypes = {
  ...TypeProps
}

export default Fab
