import { isEmpty, orderBy } from 'lodash'
import { isPastTime } from './GeneralHelpers'

export const otherEvaluationsCompletedInTimeblock = (sessions, session) => {
  const sessionsInSameTimeblock = sessions.filter(
    (s) =>
      `${s.formatted_display_date} ${s.formatted_display_time}` ===
      `${session.formatted_display_date} ${session.formatted_display_time}`
  )
  return sessionsInSameTimeblock.some((s) => s.evaluation_completed)
}

export const diffInDaysFromNow = (date) => {
  const date1 = Math.round(new Date().getTime() / 1000)
  const date2 = date
  const oneDay = 60 * 60 * 24
  const diffInTime = date2 - date1
  return Math.round(diffInTime / oneDay)
}

export const p2PSessionCheckForScheduledMeetings = (session) => {
  return session?.one_on_one_meetings.some((mtg) => mtg.id !== null)
}

export const getSession = (sessions, id) => {
  const session = isEmpty(sessions) ? {} : sessions.find(session => session.id === id)
  return session
}

export const getFilteredSessions = (sessions, filterKey) => {
  const filteredSessions = isEmpty(sessions) ? [] : sessions.filter(session => !session[filterKey])
  return filteredSessions
}

export const notRegisteredForSessions = (sessions) => {
  const registerableSessions = sessions.filter(
    (s) => !s.is_hidden && !s.itinerary
  )
  // so ignore sessions with un_reg or they're 1:1s
  const hasSelectedSessions = registerableSessions.some((session) => {
    return (
      session.registration_status === 'un_register' &&
      session.session_type_key !== 'session_type_one_to_one_meeting'
    )
  })
  return isEmpty(sessions) || registerableSessions.length === 1 ? false : !hasSelectedSessions
}

export const missingP2PEval = (session) => {
  const filtered = session.one_on_one_meetings.filter(session => session.id !== null && !session.evaluation_completed)
  return filtered.length > 0
}

export const hasMissingEvals = (sessions) => {
  if (isEmpty(sessions)) return false

  const filteredSessions = sessions.filter(session => !session.is_hidden && !session.itinerary && isPastTime(session.epoch_end_time))

  const missingEval = filteredSessions.filter(session => {
    if (session.session_type === 'Peer-to-Peer Meetings') {
      return missingP2PEval(session)
    } else {
      return !session.evaluation_completed && session.registration_status !== 'not_authorized' && !otherEvaluationsCompletedInTimeblock(sessions, session)
    }
  })

  return missingEval.length > 0
}

export const sortedEventSessionSpeakers = (session) => {
  return isEmpty(session) ? [] : orderBy(session.event_session_speakers, 'display_rank', 'asc')
}

export const scheduledSortedOneOnOneMeetings = (session) => {
  return isEmpty(session)
    ? []
    : session.one_on_one_meetings
      .sort((a, b) => b.epoch_start_time > a.epoch_start_time)
      .filter((mtg) => mtg.id !== null)
}